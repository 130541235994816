<template>
  <div class="auth">
    <Logo class="auth-logo" />
    <form class="auth-form" autocomplete="off">
      <h2>Административная панель</h2>
      <TextInput :data="form.email" placeholder="E-mail" />
      <PassInput :data="form.password" />
      <button
        :disabled="isLoading"
        @click.prevent="handleSubmit"
        class="submit"
      >
        Войти
      </button>
    </form>
  </div>
</template>

<script>
import Logo from '@/components/ui/icons/Logo';
import TextInput from '@/components/ui/TextInput';
import PassInput from '@/components/ui/PassInput';
import { MAIL } from '@/constants/InputMasks';
import { mapActions, mapState, mapMutations } from 'vuex';
import Inputmask from 'inputmask';

export default {
  name: 'Auth',
  data() {
    return {
      form: {
        email: {
          value: '',
          error: '',
          mask: MAIL,
        },
        password: {
          value: '',
          error: '',
          type: 'password',
        },
      },
    };
  },
  mounted() {
    this.clearState();
  },
  methods: {
    ...mapActions(['login']),
    ...mapMutations(['clearState']),
    errorText(field) {
      switch (field) {
        case 'email':
          return 'E-mail';
        case 'password':
          return 'пароль';
      }
    },
    buildFormData() {
      // Object.keys(this.form).map(key => {
      // ..formdata.append(key, this.form[key].value);

      // });
         const formData = new FormData();
    Object.keys(this.form).forEach(key => formData.append(key, this.form[key].value));
    return formData;
    },
    isErrorField() {
      let isFallen = false;

      Object.keys(this.form).map(key => {
        const currentItem = this.form[key];

        if (
          currentItem.mask &&
          !Inputmask.isValid(currentItem.value, currentItem.mask)
        ) {
          currentItem.error = `Неправильно ввели ${this.errorText(key)}`;
          isFallen = true;
        }

        if (currentItem.value === '') {
          currentItem.error = `Необходимо ввести ${this.errorText(key)}`;
          isFallen = true;
        }

        if (!isFallen) {
          currentItem.error = '';
        }
      });

      return isFallen;
    },
    handleSubmit() {
      if (!this.isErrorField()) {
        this.login(this.buildFormData());
      }
    },
  },
  computed: {
    ...mapState(['isLoading']),
  },
  components: {
    Logo,
    TextInput,
    PassInput,
  },
};
</script>

<style lang="scss" scoped></style>
