<template>
  <div class="input-box--white">
    <input
      class="input--white"
      ref="input"
      autocomplete="off"
      v-model="data.value"
      @change="e => onChange(e)"
      :type="data.type"
      :placeholder="placeholder"
    />
    <label v-html="data.error"></label>
  </div>
</template>

<script>
import Inputmask from 'inputmask';

export default {
  name: 'TextInput',
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    data: {
      default: {
        type: 'text',
      },
    },
  },
  mounted() {
    if (this.data.mask?.length) {
      const im = new Inputmask({
        mask: this.data.mask,
        placeholder: this.data.placeholder,
      });

      if (this.$refs.input) {
        im.mask(this.$refs.input);
      }
    }
  },
  methods: {
    onChange(e) {
      this.data.value = e.target.value;
    },
  },
};
</script>

<style scoped></style>
