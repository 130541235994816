<template>
  <div class="div--pass">
    <TextInput placeholder="Пароль" :data="data" />
    <a @click="handleTogglePass">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_930_1830"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="24"
          height="24"
        >
          <rect width="24" height="24" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_930_1830)">
          <path
            d="M16.1 13.3L14.65 11.85C14.8 11.0667 14.575 10.3333 13.975 9.64999C13.375 8.96665 12.6 8.69999 11.65 8.84999L10.2 7.39999C10.4833 7.26665 10.7707 7.16665 11.062 7.09999C11.354 7.03332 11.6667 6.99999 12 6.99999C13.25 6.99999 14.3127 7.43732 15.188 8.31199C16.0627 9.18732 16.5 10.25 16.5 11.5C16.5 11.8333 16.4667 12.146 16.4 12.438C16.3333 12.7293 16.2333 13.0167 16.1 13.3ZM19.3 16.45L17.85 15.05C18.4833 14.5667 19.046 14.0373 19.538 13.462C20.0293 12.8873 20.45 12.2333 20.8 11.5C19.9667 9.81665 18.7707 8.47899 17.212 7.48699C15.654 6.49565 13.9167 5.99999 12 5.99999C11.5167 5.99999 11.0417 6.03332 10.575 6.09999C10.1083 6.16665 9.65 6.26665 9.2 6.39999L7.65 4.84999C8.33333 4.56665 9.03333 4.35399 9.75 4.21199C10.4667 4.07065 11.2167 3.99999 12 3.99999C14.5167 3.99999 16.7583 4.69565 18.725 6.08699C20.6917 7.47899 22.1167 9.28332 23 11.5C22.6167 12.4833 22.1127 13.396 21.488 14.238C20.8627 15.0793 20.1333 15.8167 19.3 16.45ZM19.8 22.6L15.6 18.45C15.0167 18.6333 14.4293 18.771 13.838 18.863C13.246 18.9543 12.6333 19 12 19C9.48333 19 7.24167 18.3043 5.275 16.913C3.30833 15.521 1.88333 13.7167 1 11.5C1.35 10.6167 1.79167 9.79565 2.325 9.03699C2.85833 8.27899 3.46667 7.59999 4.15 6.99999L1.4 4.19999L2.8 2.79999L21.2 21.2L19.8 22.6ZM5.55 8.39999C5.06667 8.83332 4.625 9.30832 4.225 9.82499C3.825 10.3417 3.48333 10.9 3.2 11.5C4.03333 13.1833 5.229 14.5207 6.787 15.512C8.34567 16.504 10.0833 17 12 17C12.3333 17 12.6583 16.9793 12.975 16.938C13.2917 16.896 13.6167 16.85 13.95 16.8L13.05 15.85C12.8667 15.9 12.6917 15.9373 12.525 15.962C12.3583 15.9873 12.1833 16 12 16C10.75 16 9.68733 15.5627 8.812 14.688C7.93733 13.8127 7.5 12.75 7.5 11.5C7.5 11.3167 7.51233 11.1417 7.537 10.975C7.56233 10.8083 7.6 10.6333 7.65 10.45L5.55 8.39999Z"
            fill="#7B8C93"
          />
        </g>
      </svg>
    </a>
  </div>
</template>

<script>
import TextInput from '@/components/ui/TextInput';

export default {
  name: 'PassInput',
  methods: {
    handleTogglePass() {
      this.data.type === 'password'
        ? (this.data.type = 'text')
        : (this.data.type = 'password');
    },
  },
  components: {
    TextInput,
  },
  props: {
    data: Object,
  },
};
</script>

<style scoped></style>
